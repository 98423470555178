.news{
  @include media-breakpoint-up(md) {
    article:nth-child(1) {
      .post__content{
        background-color: $primary;
        &, *{
          color: #000;
        }
      }
    }
  }
  article.post:not(:first-child){
    background-blend-mode: overlay;
    background-size: cover;
  }
}

.bg-primary, .bg-secondary{
  p, a:not([class]){
    color: white;
  }
}
.bg-secondary{
  color: white;
}

.bg-primary {
  .link-primary,
  .link-secondary {
    color: $secondary !important;
  }
  .link-light {
    color: white !important;
  }
}